import { lerp } from '@kaliber/math'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { useLocationMatch } from '@kaliber/routing'
import { animated, useSpring } from 'react-spring'
import { easeOut } from '/machinery/easings'
import { determineLanguage } from '/i18n/utils'

import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './Testimonial.css'

export function Testimonial({ testimonial }) {
  const { profilePicture: image, name: name, quote: quote } = testimonial

  const [spring, api] = useSpring(() => ({
    '--progress': '0%',
    config: { tension: 500, friction: 35 }
  }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.center(200) },
    end: { element: triggers.bottom(), scrollParent: triggers.center(-200) },
    onChange: x => api.start({ '--progress': `${lerp({ start: 0, end: 100, input: easeOut(x) })}%` })
  })

  return (
    <div className={styles.component} {...{ ref }}>
      <div className={styles.avatarElement}>
        {image?.asset?._ref && (
          <ImageCover
            aspectRatio={1 / 1}
            layoutClassName={styles.avatarImageLayout}
            {...{ image }}
          />
        )}
      </div>

      <div className={styles.nameElement}>
        {name}
      </div>

      <div className={styles.quote}>
        <Blockquote {... { quote, spring }} />
      </div>
    </div>
  )
}

function Blockquote({ quote, spring }) {
  const { params: { language: locale } } = useLocationMatch()
  const language = determineLanguage(locale)
  const isLanguageWithGuillemet = ['fr', 'es', 'it'].includes(language)

  return (
    <q className={styles.componentBlockquote}>
      <animated.span className={styles.quoteHighlightElement} style={spring}>
        {isLanguageWithGuillemet
          ? <>&laquo;{quote}&raquo;</>
          : <>&quot;{quote}&quot;</>
        }
      </animated.span>
    </q>
  )
}
